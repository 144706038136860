import React from "react"
import { Jumbotron, Card } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import { Link as ReachLink } from "@reach/router"
import Img from "gatsby-image"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n/config"
import { useTranslation } from "react-i18next"

const HardwarePage = () => {
  const data = useStaticQuery(graphql`
    query {
      hardware: file(relativePath: { eq: "hardware.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const cardStyles = {
    margin: 20,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  }
  const imageStyles = {
    margin: 20,
    maxWidth: 1000,
    justifyContent: "center",
  }

  const { t } = useTranslation()
  return (
    <Jumbotron>
      <div className="container">
        <div className="row" style={{ justifyContent: "center", margin: 20 }}>
          <h1 style={{ paddingTop: 30 }}>Hardware Pricing</h1>
        </div>
        <div style={{ justifyContent: "center", margin: 20 }}>
          <Img
            fluid={data.hardware.childImageSharp.fluid}
            style={imageStyles}
          />
        </div>
      </div>
    </Jumbotron>
  )
}

export default HardwarePage
